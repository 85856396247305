<template>
    <div class="loyalty-collect-points innerContainer">
        <h2 class="header-title luxury">{{ item.title }}</h2>
        <div class="content" v-html="item.content"></div>
        <div class="image-container">
            <picture class="picture"
                     data-scroll
                     data-scroll-speed="-2"
            >
                <source
                    media="(max-width:767px)"
                    :srcset="item.mobile"
                />
                <source
                    media="(max-width:1023px)"
                    :srcset="item.tablet"
                />
                <img class="main-image"
                     :src="item.desktop"
                />
            </picture>
            <!--            <img src="../../../assets/imgs/project-inside.png" class="small-image" alt="">-->
        </div>
    </div>
</template>

<script setup>
import {computed} from "vue";


const props = defineProps({
    contentData: {
        type: Object,
    },
});
let title = computed(() => props.contentData?.conf?.header?.title)
let item = computed(() => {
    let item = props.contentData.data?.list?.[0]

    return {
        ...item,
        mobile: item?.['images-mobile']?.[0]?.devices?.desktop,
        desktop: item?.images?.[0]?.devices?.desktop,
        tablet: item?.images?.[0]?.devices?.tablet,
    }
})

</script>

<style lang="scss" scoped>
$ease: cubic-bezier(.11, 0, .21, .98);
.loyalty-collect-points {
    color: $primaryNavy;
    margin-top: 89px;

    .header-title {
        font-style: normal;
        font-weight: 400;
        font-size: 55px;
        text-transform: uppercase;
        @media only screen and (max-width: 1650px) {
            font-size: 34px;
        }
    }

    .content:deep {
        max-width: 600px;

        ul {
            list-style: none;
            margin-top: 21px;

            li {
                font-family: 'FiraGO';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;


                &::before {
                    content: "\2022";
                    color: $burgundy;
                    font-weight: bold;
                    display: inline-block;
                    font-size: 30px;
                    width: 20px;
                    font-family: 'FiraGO';
                }
            }
        }
    }

    .image-container {
        margin-top: 55px;
        position: relative;
        transform: translateY(100px);
        z-index: 2;
        @media only screen and (max-width: 1024px)  {
            transform: translateY(0);
        }
        .main-image {
            width: 100%;
        }

        .small-image {
            position: absolute;
            width: 200px;
            height: 150px;
            object-fit: cover;
            right: -20px;
            bottom: -30px;
        }
    }
}
</style>
<style lang="scss">
body {
    &.is-dark {
        .loyalty-collect-points {
            color: white;
        }
    }
}

</style>
